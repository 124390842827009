import api from "../../config/api";

// A mock function to mimic making an async request for data
export const fetchMenu = async () => {
  try {
    const { data } = await api.get("site/menu");
    return data.data;
  } catch (error) {}
};

export const fetchHomeData = async () => {
  try {
    const { data } = await api.get("site/home-data");
    return data.data;
  } catch (error) {}
};
