import React, { useState } from "react";
import Logo from "../../../../assets/logo.png";
import MobileMenuItem from "./components/mobileMenuItem";

export default function Mobile({ items }) {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="flex flex-col max-h-screen p-4 bg-white shadow-lg">
      <div className="flex flex-row justify-center pb-2 mb-4 space-x-4 text-sm border-b divide-x text-slate-500">
        <div className="grow">(67) 99602-3497</div>
        <a
          className="pl-4"
          href="http://facem.com.br/webmail"
          target={"_blank"}
        >
          Webmail
        </a>
        <a className="pl-4" href="http://facem.com.br/contato">
          Contato
        </a>
      </div>
      <div className="flex flex-row justify-between pb-2">
        <div>
          <img src={Logo} />
        </div>
        <button onClick={() => setShowMenu(!showMenu)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>
      <div
        className={`flex flex-col grow overflow-y-auto divide-y transition-all duration-700 ${
          showMenu ? "opacity-100 h-100" : "h-0 opacity-0"
        }`}
      >
        {items.map((item) => (
          <MobileMenuItem key={item.label} item={item} />
        ))}
      </div>
    </div>
  );
}
