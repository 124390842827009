import React from "react";
import TopBar from "../Topbar";
import Logo from "../../../../assets/logo.png";
import DesktopMenuItem from "./components/desktopMenuItem";

export default function Desktop({ items }) {
  return (
    <div className="z-50 bg-white w-100">
      <div className="flex flex-row items-center justify-between mx-auto mb-4 max-w-7xl">
        <div className="flex justify-center mr-10">
          <img src={Logo} className="h-20" alt="" />
        </div>
        <div className="flex flex-col grow">
          <div>
            <TopBar />
          </div>
          <div className="flex flex-col py-4 md:flex-row md:space-x-4">
            {items.map((item) => (
              <DesktopMenuItem key={item.label} item={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
