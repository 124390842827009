import React from 'react'

export default function Topbar() {
    return (
        <div className='flex py-2 md:flex-row justify-between text-sm max-w-7xl mx-auto border-b border-slate-300'>
            <div className='flex flex-row space-x-4'>
                <div className='s'>(67) 99602-3497</div>
                <a className='pl-4' href='http://facem.com.br/webmail' target={"_blank"}>Webmail</a>
                <a className='pl-4' href='http://facem.com.br/contato'>Contato</a>
            </div>
            <div>
                <div>Área restrita</div>
            </div>
            <div className='flex flex-row space-x-4'>
                <div>Face</div>
                <div>Insta</div>
                <div>Youtube</div>
            </div>
        </div>
    )
}
