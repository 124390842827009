export const parseMenu = (data, level = 0) => {
  let menu = [];
  data.map((m) => {
    let obj = new Object();
    obj.id = m.id;
    obj.label = m.name;
    obj.url = m.url || null;
    obj.target = m.newpage || null;
    obj.content = m.content || null;
    if (m.pages) {
      obj.childrens = parseMenu(m.pages, level + 1);
    }
    menu.push(obj);
  });
  return menu;
};
