import React from "react";
import { Carousel } from "@trendyol-js/react-carousel";
import { Link } from "react-router-dom";

export default function CoursesSession({ data }) {
  return (
    <div className="grid grid-cols-1 gap-4 px-6 mt-6 md:grid-cols-4">
      {data.map((course) => (
        <Link
          to={"#"}
          className="p-6 text-white duration-200 ease-in-out transform rounded-lg shadow-lg bg-slate-700 grow w-100 md:hover:scale-105 md:hover:text-yellow-300"
        >
          <div className="text-sm">curso de</div>
          <div className="text-xl uppercase">{course.name}</div>
        </Link>
      ))}
    </div>
  );
}
