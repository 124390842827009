import React from "react";

export default function TopLinksBar() {
  return (
    <div className="border-t-4 border-green-500 bg-slate-700">
      <div className="grid grid-cols-2 gap-4 py-4 mx-auto md:flex md:flex-row md:justify-between max-w-7xl">
        <img src="http://facem.com.br/assets/bio.jpg" />
        <img src="http://facem.com.br/assets/ico_area_academico.jpg" />
        <img src="http://facem.com.br/assets/ico_area_professor.jpg" />
        <img src="http://facem.com.br/assets/ico_cpa.jpg" />
        <img src="http://facem.com.br/assets/ico_revista_cientifica.jpg" />
      </div>
    </div>
  );
}
