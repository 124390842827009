import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectMenu, menuAsync } from "../../../../features/app/appSlice";

import useWindowSize from "../../../../hooks/useWindowSize";
import Desktop from "../Navbar/desktop";
import Mobile from "../Navbar/mobile";

export default function Header() {
  const size = useWindowSize();
  const menuState = useSelector(selectMenu);
  const dispatch = useDispatch();

  useEffect(() => {
    if (menuState.length === 0) {
      dispatch(menuAsync());
    }
  }, [menuState]);

  return size.width < 800 ? (
    <Mobile items={menuState} />
  ) : (
    <Desktop items={menuState} />
  );
}
