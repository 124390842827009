import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

export default function MiddleSession({ galleries }) {
  return (
    <div className="flex flex-col w-full gap-4 mx-auto md:flex-row max-w-7xl">
      <div className="flex justify-center p-6 bg-white shadow-lg md:rounded-lg md:w-fit h-80">
        <img
          src="http://facem.com.br/calendario.png"
          className="h-full"
          alt=""
        />
      </div>
      <div className="flex flex-col justify-between gap-6 p-6 bg-white shadow-lg md:flex-row md:rounded-lg">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 ">
          {galleries &&
            galleries.map((item) => (
              <Link
                key={item.id}
                to={`/galeria/${item.id}`}
                className="flex flex-col space-y-2 hover:font-semibold hover:cursor-pointer"
              >
                <div>
                  <img
                    src={item.imageUrl}
                    alt=""
                    className="rounded-lg shadow w-100"
                  />
                </div>
                <div>
                  <div className="text-xs text-slate-500">{item.date}</div>
                  <div className="text-slate-700">{item.name}</div>
                </div>
              </Link>
            ))}
        </div>
        <div className="grow text-slate-400">
          <div className="h-60">
            <iframe
              className="rounded-lg shadow"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/p5XoeM1JQTY"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <div className="flex flex-row items-center justify-end px-6 py-2 mt-2 text-sm text-slate-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              <span>mais vídeos</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
