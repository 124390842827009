import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

export default function FeaturedSession({ notices, banner }) {
  const { featured, others } = notices;
  return (
    <div className="grid grid-cols-1 p-6 mx-auto bg-white shadow-lg md:rounded-lg md:grid-cols-3 md:gap-4 md:px-4 md:max-w-7xl">
      {featured && (
        <Link
          key={featured[0].id}
          to={"/noticia/" + featured[0].slug}
          className="relative cursor-pointer hover:font-semibold"
        >
          <img
            src={featured[0].imageUrl}
            className="rounded-lg shadow"
            alt=""
          />
          <div className="w-full px-4 py-2 text-lg text-left">
            <div className="text-sm text-slate-500">
              {featured[0].date_show}
            </div>
            <div className="text-lg">{featured[0].title}</div>
          </div>
        </Link>
      )}

      {others && (
        <div className="flex flex-col md:border md:justify-between md:rounded-lg">
          <div className="flex flex-col gap-4 divide-y md:gap-2 divide-dashed">
            {others.map((notice) => (
              <div
                key={notice.id}
                className="flex flex-col pt-4 md:pt-2 md:flex-row"
              >
                <Link
                  to={`/noticia/${notice.slug}`}
                  className="px-4 md:text-sm hover:font-semibold"
                >
                  <div className="text-slate-500">{notice.date_show}</div>
                  <div className="text-lg">{notice.title}</div>
                </Link>
              </div>
            ))}
          </div>
          <div className="flex justify-center py-2 mx-4 mt-4 text-lg text-white border rounded-lg md:mt-0 m md:text-slate-700 md:text-md md:border-0 md:justify-end t-4 md:bg-white bg-slate-600">
            <Link
              to={"/noticias"}
              className="cursor-pointer hover:font-semibold"
            >
              Todas as notícias
            </Link>
          </div>
        </div>
      )}
      <div className="flex justify-center p-4 md:p-0 md:justify-end">
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
          className=""
        >
          {banner &&
            banner.map((item) => (
              <Link
                key={item.id}
                to={item.url || "#"}
                className="cursor-pointer"
              >
                <img src={item.imageUrl} alt="" className="rounded-lg" />
              </Link>
            ))}
        </Carousel>
      </div>
    </div>
  );
}
