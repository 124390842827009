import React, { useState } from "react";

export default function DesktopMenuItem({ item }) {
  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleResolveLink = (child) => {
    return child.url ? child.url : "/pagina/" + child.id;
  };

  return (
    <div className="z-50 flex flex-col">
      <div className="flex flex-row items-center justify-between h-14">
        <button
          onMouseEnter={() => setShowSubMenu(true)}
          onClick={() => setShowSubMenu(!showSubMenu)}
          className={`flex flex-row justify-between items-center w-full  ${
            showSubMenu && "font-semibold"
          }`}
        >
          <div className="">{item.label}</div>
          <div>
            {showSubMenu ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
        </button>
      </div>
      <div
        onMouseLeave={() => setShowSubMenu(false)}
        className={`${
          showSubMenu ? "block" : "hidden"
        } absolute pl-4 space-y-4 py-2 divide-y w-auto px-4 flex flex-col bg-white border translate-y-14 drop-shadow-lg rounded-lg`}
      >
        {item.childrens &&
          item.childrens.map((child) => (
            <a
              key={child.label.toString()}
              href={handleResolveLink(child)}
              target={child.target ? "_blank" : "_self"}
              rel="noopener noreferrer"
              className="flex flex-row items-center justify-start pt-2 hover:font-semibold last:pb-2"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div>{child.label}</div>
            </a>
          ))}
      </div>
    </div>
  );
}
