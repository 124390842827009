import axios from "axios";

import { ENDPOINT_API } from "./constants";

const onRequest = (config) => {
  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (error) => {
  return Promise.reject(error);
};

const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

const api = setupInterceptorsTo(
  axios.create({
    baseURL: ENDPOINT_API,
    headers: {
      Accept: "application/json",
    },
  })
);

export default api;
