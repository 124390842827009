import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { parseMenu } from "../../helpers/appHelper";
import { fetchHomeData, fetchMenu } from "./appAPI";

const initialState = {
  loading: false,
  menu: [],
  homeData: {},
};

export const menuAsync = createAsyncThunk("app/fetchMenu", async () => {
  return await fetchMenu();
});

export const homeAsync = createAsyncThunk("app/fetchHomeData", async () => {
  return await fetchHomeData();
});

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    setHomeData: (state, action) => {
      state.homeData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(menuAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(menuAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.menu = parseMenu(action.payload);
      })
      .addCase(homeAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(homeAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.homeData = action.payload;
      });
  },
});

export const { setLoading, setMenu } = appSlice.actions;

export const selectLoading = (state) => state.app.loading;
export const selectMenu = (state) => state.app.menu;
export const selectHomeData = (state) => state.app.homeData;

export default appSlice.reducer;
