import React from "react";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectLoading } from "./features/app/appSlice";

import Layout from "./app/components/Layout/Base";
import Home from "./app/pages/Home";
import Contact from "./app/pages/Contact";
import NotFound from "./app/pages/NotFound";
import Loading from "./app/components/Loading";

const App = () => {
  const loading = useSelector(selectLoading);
  return (
    <div className="bg-slate-200">
      {loading && <Loading />}
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/contato" element={<Contact />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
