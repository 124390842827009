import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function MobileMenuItem({ item }) {
  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleResolveLink = (child) => {
    return child.url ? child.url : "/pagina/" + child.id;
  };

  return (
    <div className="flex flex-col justify-between text-lg">
      <button
        onClick={() => setShowSubMenu(!showSubMenu)}
        className="flex flex-row justify-between w-full py-4"
      >
        <div className="font-semibold">{item.label}</div>
        <div>
          {showSubMenu ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          )}
        </div>
      </button>
      <div
        className={`${
          showSubMenu ? "block" : "hidden"
        } pl-4 space-y-4 pb-4 divide-y w-full flex flex-col`}
      >
        {item.childrens &&
          item.childrens.map((child) => (
            <a
              key={child.label}
              href={handleResolveLink(child)}
              target={child.target ? "_blank" : "_self"}
              rel="noopener noreferrer"
              className="flex flex-row items-center justify-start py-2"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div>{child.label}</div>
            </a>
          ))}
      </div>
    </div>
  );
}
