import React from "react";

export default function Footer() {
  return (
    <div className="p-6 mt-6 text-white border-t-4 border-t-green-600 bg-slate-700">
      <div>footer</div>
      <div className="text-sm text-center">
        (C) 2022. Facem - Todos os Direitos Reservados
      </div>
    </div>
  );
}
