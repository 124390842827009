import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

export default function SliderHome({ data }) {
  return (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      showThumbs={false}
      className="border-b-4 border-green-500"
    >
      {data &&
        data.map((banner) => (
          <Link
            key={banner.id}
            to={banner.url || ""}
            className="cursor-pointer"
          >
            <img src={banner.imageUrl} alt="" />
          </Link>
        ))}
    </Carousel>
  );
}
