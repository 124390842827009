import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { homeAsync, selectHomeData } from "../../../features/app/appSlice";
import SliderHome from "../../components/SliderHome";
import TopLinksBar from "../../components/TopLinksBar";
import CoursesSession from "./components/coursesSession";
import FeaturedSession from "./components/featuredSession";
import MiddleSession from "./components/middleSession";
export default function Home() {
  const homeData = useSelector(selectHomeData);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Facem";
    dispatch(homeAsync());
  }, []);

  return (
    <>
      <TopLinksBar />
      {homeData && (
        <>
          <SliderHome data={homeData.superbanner} />
          <div className="flex flex-col gap-4">
            {homeData.courses && <CoursesSession data={homeData.courses} />}
            {homeData.notices && (
              <FeaturedSession
                notices={homeData.notices}
                banner={homeData.featuredBanner}
              />
            )}
            <MiddleSession galleries={homeData.galleries} />
          </div>
        </>
      )}
    </>
  );
}
